import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";
import RequestDest from "@/models/ext/request-dest";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FJntService {
    getApiUrl() {
        return API_URL
    }

    getPostNewJntTarif(fwarehouseBean, fexpedisiBean, kabKota, kecamatan, weight, serviceDisplay){
        const requestDest = new RequestDest(
            fwarehouseBean,
            fexpedisiBean,
            kabKota,
            kecamatan,
            weight,
            serviceDisplay
        )

        return axios.post(API_URL + `getPostNewJntTarif`, requestDest, { headers: authHeader() });
    }

    getJntTarif(fwarehouseBean, fexpedisiBean, destAreaCode, weight){

        // console.log(`${fwarehouseBean} >> ${fexpedisiBean} >> ${destAreaCode}`)

        return axios.get(API_URL + `getJntTarif/${fwarehouseBean}/${fexpedisiBean}/${destAreaCode}/${weight}`, { headers: authHeader() });
    }
    generateJntAwb(fwarehouseBean, fexpedisiBean, ftSaleshBean){
        return axios.post(API_URL + `generateJntAwb/${fwarehouseBean}/${fexpedisiBean}`, ftSaleshBean, {headers: authHeader()})
    }
    getJntTracking(fwarehouseBean, fexpedisiBean, awbNumber){
        return axios.get(API_URL + `getJntTracking/${fwarehouseBean}/${fexpedisiBean}/${awbNumber}`, { headers: authHeader() });
    }

}
export default new FJntService()
