export default class FExpedisiTrackHistory {
  constructor(
    id=0,

    strTime,
    status="",
    remark="",

    kurirName="",
    kurirPhone = "",
    receiver = "",
    iconCode =0,
    imagePath


  ) {
    this.id = id;

    this.strTime = strTime;
    this.status = status;
    this.remark = remark;

    this.kurirName = kurirName;
    this.kurirPhone = kurirPhone;
    this.receiver = receiver;
    this.iconCode = iconCode;

    this.imagePath = imagePath;

  }

}
