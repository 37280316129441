
const ESchemaSalesDetail = Object.freeze([
    {
        column: 'Nomor',
        type: Number,
        value: item => item.id
    },
    {
        column: 'OrderNo',
        type: String,
        value: item => item.orderno
    },
    {
        column: 'Tanggal Order',
        type: Date,
        format: 'dd-MMM-yyyy',
        value: item => new Date(item.orderDate)
    },
    {
        column: 'InvoiceNo',
        type: String,
        width:20,
        value: item => item.invoiceno
    },
    {
        column: 'Tgl Invoice',
        type: Date,
        format: 'dd-MMM-yyyy',
        value: item => new Date(item.invoiceDate)
    },
    {
        column: 'DeliveryNumber',
        type: String,
        width:20,
        value: item => item.deliveryNumber
    },
    {
        column: 'Tanggal Delivery',
        type: Date,
        format: 'dd-MMM-yyyy',
        value: item => new Date(item.deliveryDate)
    },
    {
        column: 'Warehouse',
        type: String,
        width:15,
        value: item => item.fwarehouse.kode1
    },
    {
        column: 'Expedisi',
        type: String,
        width:15,
        value: item => item.fexpedisi.kode1
    },
    {
        column: 'Status Pengiriman',
        type: String,
        width:20,
        value: item => item.statusKirim
    },
    {
        column: 'Salesman',
        type: String,
        width:25,
        value: item => item.fsalesman.spname
    },
    {
        column: 'Distributor',
        type: String,
        width:25,
        value: item => item.fdivision.description
    },
    {
        column: 'Nama Toko',
        type: String,
        width:25,
        value: item => item.fstore.description
    },
    {
        column: 'Kode Member',
        type: String,
        value: item => item.fcustomer.custno
    },
    {
        column: 'Member/Customer',
        type: String,
        width:25,
        value: item => item.fcustomer.custname
    },
    {
        column: 'Nama Penerima',
        type: String,
        width:20,
        value: item => item.destName
    },
    {
        column: 'Nomor HP',
        type: Number,
        width:15,
        value: item => item.destPhone
    },
    {
        column: 'Alamat Penerima',
        type: String,
        width:30,
        value: item => item.destAddress1
    },
    {
        column: 'Kecamatan Penerima',
        type: String,
        width:25,
        value: item => item.destCity1
    },
    {
        column: 'Propinsi Penerima',
        type: String,
        width:25,
        value: item => item.destState1
    },
    {
        column: 'Kode Tujuan(Expedisi)',
        type: String,
        width:25,
        value: item => item.destCode
    },
    {
        column: 'Kode Barang',
        type: String,
        width:15,
        value: item => item.pcode
    },
    {
        column: 'Base Price(Price Asal)',
        type: Number,
        width:15,
        value: item => {
            try {
                item.fmaterialBaseSprice
            }catch (e) {
                console.log(e)
            }
        }
    },
    {
        column: 'Disc Persen',
        type: Number,
        width:10,
        value: item => item.discPersen
    },
    {
        column: 'Nama Barang',
        type: String,
        width:25,
        value: item => item.pname
    },
    {
        column: 'Qty',
        type: Number,
        width:10,
        value: item => item.qty
    },
    {
        column: 'Sprice Reserved-1',
        type: Number,
        width:10,
        value: item => item.sprice2ToDist1
    },
    {
        column: 'Sprice Reserved-2',
        type: Number,
        width:10,
        value: item => item.sprice2ToDist2
    },
    {
        column: 'Sprice Reserved-3',
        type: Number,
        width:10,
        value: item => item.sprice2ToDist3
    },


    {
        column: 'Total Items AfterDisc',
        type: Number,
        format: '#,##0',
        value: item => item.amountAfterDiscPlusRpAfterPpn_FG
    },
    {
        column: 'Ongkir',
        type: Number,
        format: '#,##0',
        value: item => item.ongkirRp
    },
    {
        column: 'Penanganan COD',
        type: Number,
        format: '#,##0',
        value: item => item.byPenanganan
    },
    {
        column: 'Uang Masuk',
        type: Number,
        format: '#,##0',
        value: item => item.totalBayar
    },


])


export default ESchemaSalesDetail