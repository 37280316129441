<template>
  <div>
    <v-dialog
        v-model="dialogShow"
        :max-width="formDialogOptions.width"
        :style="{ zIndex: formDialogOptions.zIndex }"
        @keydown.esc.prevent="closeForm"
        persistent
        :fullscreen="$vuetify.breakpoint.smAndDown"
        :hide-overlay="$vuetify.breakpoint.smAndDown"
    >
      <v-card flat>
        <v-toolbar
            dark
            color="brown"
            class="color-gradient-1"
            dense
        >
          <v-btn
              icon
              dark
              @click="closeForm"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-toolbar-items >

            <v-btn
              dark
              text
              v-if="kurir.phone"
              plain
              :href="`https://api.whatsapp.com/send?phone=${kurir.phone}&text=Hi, `" target="_blank"
            >
              <div >
                <span>
                  {{ kurir.name }}
                </span>
                <span class="ml-1">
                  {{ kurir.phone }}
                </span>
                <v-icon small color="green lighten-3" class="ml-1">mdi-whatsapp</v-icon>
              </div>
            </v-btn>

          </v-toolbar-items>
        </v-toolbar>

          <v-card-text>
            <v-timeline
                align-top
                dense
            >
              <v-timeline-item
                  color="grey"
                  small
              >
                <v-row no-gutters>
                  <v-col cols="4" sm="4" md="4">
                    <span  class="text-caption">{{ computedDateFormattedDatefnsWithTime(this.itemModified.orderDate) }}</span>
                  </v-col>
                  <v-col cols="8" sm="8" md="8">
                    <strong>{{ lookupEStatusPengiriman(0).shortDesc }}</strong>
                    <div class="text-caption">
                      {{ lookupEStatusPengiriman(0).description }}
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item
                  color="blue lighten-3"
                  small
                  v-if="itemModified.deliveryNumber"
              >
                <v-row no-gutters>
                  <v-col cols="12" sm="4" md="4">
                    <span class="text-caption">{{ computedDateFormattedDatefnsWithTime(this.itemModified.deliveryDate) }}</span>
                  </v-col>
                  <v-col cols="12" sm="8" md="8">
                    <strong>{{ lookupEStatusPengiriman(1).shortDesc }}</strong>
                    <div class="text-caption">
                      {{ lookupEStatusPengiriman(1).description }} RESI: <span class="font-weight-bold blue--text">{{this.itemModified.deliveryNumber}}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <div v-for="(item, index) in itemsTracking" :key="index">
                <v-timeline-item
                    color="blue lighten-3"
                    small
                    v-if="itemModified.deliveryNumber"
                    :icon="lookupIconEStatusPengiriman(item)"
                >
                  <v-row no-gutters>
                    <v-col cols="12" sm="4" md="4">
                      <span class="text-caption">
                        {{ item.time }}
                      </span>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <div class="text-caption">
                        <span class="red--text" v-if="item.iconCode===401">
                          {{ item.status }}
                        </span>
                        <span v-else>
                          {{ item.status }}
                        </span>
                      </div>

                      <div v-if="item.kurirName">
                        <span>
                          Kurir: {{ item.kurirName }}
                        </span>
                        <span class="ml-1">
                          <v-icon x-small>mdi-whatsapp</v-icon>
                          {{ item.kurirPhone }}
                        </span>
                      </div>

                      <div v-if="item.receiver">
                          Penerima: {{ item.receiver }}
                      </div>
                      <div v-if="item.imagePath">
                        <v-img
                            height="300"
                            width="250"
                            :src="item.imagePath"
                        ></v-img>
                      </div>

                    </v-col>
                  </v-row>
                </v-timeline-item>
              </div>

            </v-timeline>

          </v-card-text>

      </v-card>


    </v-dialog>
  </div>
</template>

<script>


import FtSalesh from "../../models/ft-salesh";
import {format, parseISO} from "date-fns";
import {EStatusPengirimans} from "../../models/e-status-pengiriman";
import FJntService from "../../services/api_ext/f-jnt-service"
import FJneService from "../../services/api_ext/f-jne-service"
import FExpedisiTrackHistory from "../../models/f-expedisi-track-history";
import FIdExpressService from "@/services/api_ext/f-id-express-service";
import FSiCepatService from "@/services/api_ext/f-si-cepat-service";

export default {
  name: "FtSaleshTimeLineDialog",
  data(){
    return {
      title: '',

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      itemModified: new FtSalesh(),
      itemsEStatusPengiriman: EStatusPengirimans,

      itemsTracking: [],
      details: undefined,
      kurir: {
        name: '',
        phone: ''
      }

    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },

    haveRoleAdmin(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_ADMIN")  ||
            this.currentUser.roles.includes("ROLE_ADMIN1")  ||
            this.currentUser.roles.includes("ROLE_ADMIN2")  ){

          isValid=true
        }
      }
      return isValid
    },
    itemsFExpedisi(){
      return this.$store.state.data.listFExpedisi
    },

    itemsTrackingComputed(){
      let items =  []

      // this.itemsTracking.forEach( item => {
      //   // const item = Object
      //   //
      //   // items.push(item)
      //   console.log(item)
      // })

      return items
    },

  },
  methods: {
    closeForm(){
      this.dialogShow = false
    },
    showDialog(item) {
      this.dialogShow = true
      // console.log(item)
      this.itemModified = item
      this.initializeEditMode(item)
    },

    initializeEditMode(item){
      if (this.lookupFExpedisi(item.fexpedisiBean).toUpperCase().includes("JNT") ||
          this.lookupFExpedisi(item.fexpedisiBean).toUpperCase().includes("J&T") ){

        this.itemsTracking = []
        this.kurir.name = ''
        this.kurir.phone = ''

        FJntService.getJntTracking(item.fwarehouseBean, item.fexpedisiBean, item.deliveryNumber.trim()).then(
            response =>{

              console.log("Tracking of: JNT")
              if (this.haveRoleAdmin){
                console.log(JSON.stringify(response.data))
              }

              this.title = "JNT"

              const {history, detail} = response.data
              // this.itemsTracking = history
              this.itemsTracking = []
              let counter=0
              for ( let i in history){
                let show = true
                const itemHistory = new FExpedisiTrackHistory()
                itemHistory.id =counter++
                itemHistory.time = this.computedDateFormattedDatefnsWithTime(history[i].date_time)

                let status_code = history[i].status_code
                let description = `[${status_code}] ${history[i].status}`
                let note = ''
                try{
                  note = `${history[i].note}`
                }catch (e) {
                  e.toString()
                }

                if (status_code != 101) {
                  if (status_code == 100 && description.includes('Paket akan dikirimkan ke') && counter <3) {
                    description += ` [${history[i].city_name}]`
                    itemHistory.iconCode = 2
                  }else if (status_code ==100 && description.includes('Paket telah diterima oleh') && counter <5){
                    description += ` [${history[i].city_name}]`
                  }else if (status_code ==100 && description.includes('Paket akan dikirimkan ke') && counter <6){
                    description += ` [${history[i].city_name}]`
                    itemHistory.iconCode = 3
                  }else if (status_code ==100 && description.includes('Paket akan dikirim ke alamat penerima') ){
                    description += ` [${history[i].city_name}]`
                    itemHistory.kurirName = history[i].driverName
                    itemHistory.kurirPhone = history[i].driverPhone
                    itemHistory.iconCode = 5

                  }else if (status_code ==100){
                    description += ` [${history[i].city_name}]`
                  }else {
                    description += ` [${history[i].city_name}]`
                  }
                }

                if (status_code == 200) {
                  if (history[i].receiver !==''){
                    itemHistory.receiver = ` oleh ${history[i].receiver}`
                    itemHistory.iconCode = 6
                  }
                }

                itemHistory.status = description
                if (note !== '' && note.trim().toUpperCase() !== 'MOBIL'
                    && note.trim().toUpperCase() !== 'PESAWAT') {
                  itemHistory.status = `${description} | ${note}`
                }

                if (status_code ==101){
                  show =false
                }

                if (show){
                  this.itemsTracking.push(itemHistory)
                }

              }

              // console.log(JSON.stringify(detail.driver))

              // this.details = detail
              this.kurir.name = detail.delivDriver.name
              this.kurir.phone = detail.delivDriver.phone

              // console.log(this.kurir.phone)

            },
            error =>{
              const pesan = error.response.data
              if (pesan.includes("Invalid AWB number")){
                const itemHistory = new FExpedisiTrackHistory()
                itemHistory.iconCode = 401
                itemHistory.status = "JNT tidak dapat mengenali nomor AWB Tersebut"
                this.itemsTracking.push(itemHistory)
              }
            }
        )
      }else if (this.lookupFExpedisi(item.fexpedisiBean).toUpperCase().includes("JNE") ){

        this.itemsTracking = []
        this.kurir.name = ''
        this.kurir.phone = ''

        FJneService.getJNETracking(item.fwarehouseBean, item.fexpedisiBean, item.deliveryNumber.trim()).then(
            response => {

              console.log("Tracking of: JNE")
              if (this.haveRoleAdmin){
                console.log(JSON.stringify(response.data))
              }

              this.title = "JNE"

              const {history, detail, cnote} = response.data
              detail.toString()
              cnote.toString()

              this.itemsTracking = []
              let counter=0
              for ( let i in history){
                const itemHistory = new FExpedisiTrackHistory()
                itemHistory.id =counter++
                itemHistory.time = history[i].date
                itemHistory.status = `[${history[i].code}] ${history[i].desc}`

                this.itemsTracking.push(itemHistory)
              }

              // console.log(cnote.photo)

              if (cnote.photo != ''){
                const lastHistory = this.itemsTracking[counter-1]
                lastHistory.imagePath = cnote.photo
                this.itemsTracking[counter-1] = lastHistory
                // console.log(JSON.stringify(lastHistory.imagePath))
              }

            },
        )

      }else if (this.lookupFExpedisi(item.fexpedisiBean).toUpperCase().includes("ID") && this.lookupFExpedisi(item.fexpedisiBean).toUpperCase().includes("EXPRESS") ){
        FIdExpressService.getIdExpressTracking(item.fwarehouseBean, item.fexpedisiBean, item.deliveryNumber.trim()).then(
            response => {
              console.log("Tracking of: ID Expresss")
              if (this.haveRoleAdmin){
                console.log(JSON.stringify(response.data))
              }

              if (this.currentUser ==='ROLE_ADMIN'){
                console.log(JSON.stringify(response.data))
              }

              this.title = "ID EXPRESS"

              const history = response.data.data.historys
              // const last_status = response.data.sicepat.result.last_status
              // const pop_img_path = response.data.sicepat.result.pop_img_path

              // console.log(JSON.stringify(history))
              // const {history, detail, cnote} = response.data
              // detail.toString()
              // cnote.toString()

              this.itemsTracking = []
              let counter=0
              for ( let i in history){
                const itemHistory = new FExpedisiTrackHistory()
                itemHistory.id =counter++
                const waktuOperasi = new Date(history[i].operationTime);
                itemHistory.time = `${waktuOperasi.toLocaleDateString()} ${waktuOperasi.toLocaleTimeString()}`
                itemHistory.timeMillis = history[i].operationTime

                itemHistory.status = `${history[i].operationType} by ${history[i].courierName} [${history[i].currentBranch}]`

                this.itemsTracking.push(itemHistory)
              }

              this.itemsTracking.sort((a, b) => (a.timeMillis > b.timeMillis) ? 1 : -1)

            },
        )

      }else if (this.lookupFExpedisi(item.fexpedisiBean).toUpperCase().includes("SI") && this.lookupFExpedisi(item.fexpedisiBean).toUpperCase().includes("CEPAT") ){
        FSiCepatService.getSiCepatTracking(item.fwarehouseBean, item.fexpedisiBean, item.deliveryNumber.trim()).then(
            response => {
              console.log("Tracking of: SI Cepat")
              if (this.haveRoleAdmin){
                console.log(JSON.stringify(response.data))
              }

              this.title = "SI CEPAT"

              const history = response.data.sicepat.result.track_history
              const last_status = response.data.sicepat.result.last_status
              const pop_img_path = response.data.sicepat.result.pop_img_path

              // console.log(JSON.stringify(history))
              // const {history, detail, cnote} = response.data
              // detail.toString()
              // cnote.toString()
              //
              this.itemsTracking = []
              let counter=0
              for ( let i in history){
                const itemHistory = new FExpedisiTrackHistory()
                itemHistory.id =counter++
                itemHistory.time = history[i].date_time
                itemHistory.status = `[${history[i].status}] ${history[i].city}`

                this.itemsTracking.push(itemHistory)
              }

              const lastItemHistory = new FExpedisiTrackHistory()
              lastItemHistory.id =counter++
              lastItemHistory.time = last_status.date_time
              lastItemHistory.status = `${last_status.city}`
              lastItemHistory.imagePath = `${pop_img_path}`
              this.itemsTracking.push(lastItemHistory)

            },
        )
      }

    },
    lookupFExpedisi (fexpedisiBean) {
      const str = this.itemsFExpedisi.filter(x => x.id===fexpedisiBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },
    lookupEStatusPengiriman (statusPengiriman) {
      const str = this.itemsEStatusPengiriman.filter(x => x.id===statusPengiriman)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupIconEStatusPengiriman(itemHistory){
      // console.log(itemHistory)

      let strIcon = ''
      if (itemHistory.iconCode ==1){
        strIcon = ''
      }else if (itemHistory.iconCode==2){
        strIcon = 'mdi-cube-send'
      }else if (itemHistory.iconCode==3){
        strIcon = 'mdi-truck-fast'
      }else if (itemHistory.iconCode==5){
        strIcon = 'mdi-motorbike'
      }else if (itemHistory.iconCode==6){
        strIcon = 'mdi-account-check'
      }else if (itemHistory.iconCode==401){
        strIcon = 'mdi-comment-alert'
      }
      return strIcon
    },

    computedDateFormattedDatefnsWithTime(value) {
      let strDate = ''
      try{
        strDate = value ? format(parseISO(value), 'yyyy-MM-dd HH:mm') : ''
      }catch (e) {
        e.toString()
      }
      return strDate
    },


  }
}
</script>

<style scoped>

</style>