<template>

  <v-card class="elevation-0 color-top-left-gradient-3">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>

      <v-text-field
          v-on:keyup.enter="searchOnEnter"
          v-on:blur="searchOnEnter"
          append-icon="mdi-magnify"
          hint="No Invoice, Nama Customer, No HP(press ENTER to search)"
          label="Search"
      ></v-text-field>
      <v-btn
          @click="showFilterDialog"
          icon fab
          color="blue"
          small
          class="ml-2"
      >
        <v-icon>mdi-filter</v-icon>
      </v-btn>

    </v-card-title>
    <v-expand-transition>

      <v-card class="mx-3 mb-2 elevation-7 color-top-left-gradient-3 lighten-5 rounded-lg" v-show="showFilter===true">
        <v-card-text>
          <div v-if="false">
            <v-row v-if=" ! haveRoleCSOnly">
              <v-col
                  cols="12"
                  sm="4"
                  md="4"
              >
                <v-autocomplete
                    v-model="filterFdivisions"
                    :items="itemsFDivision"
                    item-value="id"
                    item-text="description"
                    dense
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    label="Distributor"
                    multiple
                    hide-details
                ></v-autocomplete>
              </v-col>
              <v-col
                  cols="12"
                  sm="4"
                  md="4"
              >
                <v-autocomplete
                    v-model="filterFsalesmans"
                    :items="itemsFSalesman"
                    item-value="id"
                    item-text="spname"
                    dense
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    label="Salesman/CS"
                    multiple
                    hide-details
                ></v-autocomplete>
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
                  md="4"
              >
                <v-autocomplete
                    v-model="filterFstores"
                    :items="itemsFStore"
                    item-value="id"
                    item-text="description"
                    dense
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    label="Store"
                    multiple
                    hide-details
                ></v-autocomplete>
              </v-col>

            </v-row>

            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-flex class="d-flex justify-end">
                  <span class="subtitle-2 mr-2">
                    Tanggal Order
                  </span>
                  <DateRangePicker
                      v-model="datePickerRangeOrder"
                      :locale-data="dprLocale"
                      @update="changeDatePickerRange"
                      :autoApply="dprAutoApply"
                      :showDropdowns="dprShowDropdowns"
                  ></DateRangePicker>
                </v-flex>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-flex class="d-flex justify-end">
                  <span class="subtitle-2 mr-2">
                    Tanggal Invoice
                  </span>
                  <DateRangePicker
                      v-model="datePickerRangeInv"
                      :locale-data="dprLocale"
                      @update="changeDatePickerRange"
                      :autoApply="dprAutoApply"
                      :showDropdowns="dprShowDropdowns"
                  ></DateRangePicker>
                </v-flex>
              </v-col>

              <v-col
                  cols="12"
                  sm="4"
                  md="4"
              >
                <v-autocomplete
                    v-model="filterFexpedisies"
                    :items="itemsFExpedisi.filter(x => x.statusActive)"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    dense
                    chips
                    small-chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    label="Expedisi"
                    persistent-hint
                    multiple
                    hide-details
                >
                  <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeAutoCompleteFExpedisi(data.item)"
                    >
                      <v-avatar left>
                        <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                      </v-avatar>
                      <span class="font-weight-regular caption"> {{ data.item.kode1 }} </span>
                    </v-chip>
                  </template>

                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{data.item.description}}</v-list-item-title>
                        <v-list-item-subtitle>{{data.item.kode2}}</v-list-item-subtitle>

                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

              </v-col>

              <v-col
                  cols="12"
                  sm="4"
                  md="4"
              >
                <v-autocomplete
                    v-model="filterEstatusPengiriman"
                    :items="itemsEStatusPengiriman"
                    item-value="id"
                    item-text="shortDesc"
                    dense
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    label="Status Pengiriman"
                    multiple
                    hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                  cols="12"
                  sm="4"
                  md="4"
              >
                <v-autocomplete
                    v-model="filterFwarehouses"
                    :items="itemsFWarehouseActive"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    dense
                    chips
                    small-chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    label="Warehouse"
                    persistent-hint
                    multiple
                    hide-details
                >
                  <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeAutoCompleteFWarehouse(data.item)"
                    >
                      <v-avatar left>
                        <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                      </v-avatar>
                      <span class="font-weight-regular caption"> {{ data.item.kode1 }} </span>
                    </v-chip>
                  </template>

                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{data.item.description}}</v-list-item-title>
                        <v-list-item-subtitle>{{data.item.kode1}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

              </v-col>


              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-autocomplete
                    autofocus
                    v-model="filterFmaterials"
                    :items="itemsFMaterial"
                    item-value="id"
                    item-text="pcode"
                    auto-select-first
                    dense
                    chips
                    small-chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    label="Product"
                    persistent-hint
                    multiple
                    hide-details
                >
                  <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeAutoCompleteFMaterial(data.item)"
                    >
                      <v-avatar left>
                        <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                      </v-avatar>
                      <span class="font-weight-bold caption ml-1 blue--text"> {{ data.item.pcode }} </span>
                    </v-chip>
                  </template>

                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-avatar>
                        <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="blue--text">{{data.item.pcode}}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="caption">
                            {{data.item.pname}}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="2" md="2">
                <v-btn color="primary" small @click="runExtendedFilter">
                  Terapkan
                  <v-icon small class="ml-1" color="green lighten-2">mdi-filter</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  sm="8"
                  md="8"
              >

                <v-autocomplete
                    v-model="filterByGroupMaterialOrdered"
                    :items="itemsGroupMaterialOrdered"
                    item-value="id"
                    item-text="description"
                    dense
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    label="Items Group"
                    multiple
                    hide-details
                ></v-autocomplete>

              </v-col>
              <v-col cols="6" sm="2" md="2">
                <v-btn color="green" small @click="runExtendedFilter"
                       v-if="itemsGroupMaterialOrdered.length>0"
                       :disabled="filterByGroupMaterialOrdered.length===0"
                >
                  Terapkan
                  <v-icon small class="ml-1" color="red">mdi-filter</v-icon>
                </v-btn>
              </v-col>

            </v-row>
          </div>

          <FtSaleshFilterExt
              v-if="true"
              :salesFilter="true"
              :itemsGroupMaterialOrdered="itemsGroupMaterialOrdered"
              @runExtendedFilterFromChild="runExtendedFilterFromChild"
          ></FtSaleshFilterExt>

        </v-card-text>
      </v-card>

    </v-expand-transition>

    <div>
      <v-skeleton-loader
          v-if="loading"
          class="mx-auto"
          type="table"
          transition="scale-transition"
      >
      </v-skeleton-loader>

      <v-data-table
          v-if="! loading"
          v-model="selectedItems"
          :single-select="!multiSelect"
          :show-select="multiSelect"
          :headers="headers"
          :items="ftSaleshsFiltered"
          :page.sync="currentPage"
          :items-per-page="pageSize"
          hide-default-footer
          class="elevation-0"
          @page-count="totalTablePages =totalPaginationPages "
          align="start"
      >
        <template v-slot:top>
          <v-row align="center" class="ml-4 mr-4">
            <v-switch
                v-model="multiSelect"
                :label="multiSelect?'Multi Select':'Single Select'"
                x-small
                class="pa-3"
                v-if="false"
            ></v-switch>
<!--            v-if="haveRoleAdmin || haveRoleInventory || haveRolePiutang"-->
            <v-btn
                fab
                dark
                color="blue"
                x-small
                :disabled="!multiSelect"
                class="mr-2"
                @click="showConfirmDialogCreateAwb"
                v-if="haveRoleAdmin"
            >
              <v-icon>mdi-truck-fast</v-icon>
            </v-btn>

            <v-btn
                fab
                dark
                color="deep-purple darken-2"
                x-small
                :disabled="!multiSelect"
                class="mr-2"
                @click="showConfirmDialogStatusOnPacking"
            >
              <v-icon>mdi-cube-outline</v-icon>
            </v-btn>
            <v-btn
                fab
                dark
                color="deep-orange accent-3"
                x-small
                :disabled="!multiSelect"
                @click="showConfirmDialogPrintAwb"
                class="mr-2"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
            <v-menu offset-y style="align-items: start"
            >
              <template v-slot:activator="{ on, attrs }" >
                <v-btn
                    class="ml-2"
                    fab
                    dark
                    x-small
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-view-grid</v-icon>
                </v-btn>

              </template>

              <v-list color="grey lighten-4" >
                <v-list-item v-if="false">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                  >
                    <v-icon color="green" small>mdi-file-excel</v-icon>
                    JNE
                  </v-btn>
                </v-list-item>
                <v-list-item v-if="false">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                  >
                    <v-icon color="green" small>mdi-file-excel</v-icon>
                    J&T
                  </v-btn>
                </v-list-item>

                <v-list-item v-if="haveRoleAdmin ||  haveRoleSalesOrCS">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                      @click="exportSalesCS"
                  >
                    <v-icon color="green" small>mdi-file-excel</v-icon>
                    Export Sales CS
                  </v-btn>
                </v-list-item>

                <v-list-item v-if="haveRoleAdmin || haveRoleInventory || haveRolePiutang">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                      @click="exportSales"
                  >
                    <v-icon color="green" small>mdi-file-excel</v-icon>
                    Export Sales
                  </v-btn>
                </v-list-item>

                <v-list-item v-if="haveRoleAdmin || haveRolePiutang || haveRoleInventory">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                      @click="exportSalesDetailBukaPaket"
                  >
                    <v-icon color="green" small>mdi-file-excel</v-icon>
                    Export Sales Detil (Dalam Paket)
                  </v-btn>
                </v-list-item>

                <v-list-item v-if="false">
                  <v-btn
                      plain
                      elevation="0"
                      color="gray darken-1"
                      small
                      @click="exportSalesDetailAccounting"
                  >
                    <v-icon color="green" small>mdi-file-excel</v-icon>
                    Export Sales For Accounting
                  </v-btn>
                </v-list-item>

                <!--                <v-divider></v-divider>-->
                <!--                <v-list-item v-if="haveRoleAdmin || haveRoleInventory || haveRolePiutang">-->
                <!--                  <v-btn-->
                <!--                      plain-->
                <!--                      elevation="0"-->
                <!--                      color="gray darken-1"-->
                <!--                      small-->
                <!--                      @click="importDialogShow"-->
                <!--                  >-->
                <!--                    <v-icon color="blue">mdi-upload</v-icon>-->
                <!--                    Import Sales-->
                <!--                  </v-btn>-->
                <!--                </v-list-item>-->
              </v-list>

            </v-menu>

          </v-row>
        </template>

        <template v-slot:[`item.number`]="{ index }">
          {{ (index + ((currentPage-1)*pageSize) +1 )}}
        </template>

        <template v-slot:[`item.invoiceno`]="{ item }">
          <div class="fill-height">
            <div class="caption">
              #{{item.invoiceno}}
            </div>
            <div class="font-weight-bold caption"  v-if="item.invoiceno">
              {{ dateFormattedDatefnsShort(item.invoiceDate) }}
            </div>
            <div class="caption grey--text">
              {{ lookupFSalesman(item.fsalesmanBean).spname }}
            </div>

          </div>
        </template>

        <template v-slot:[`item.statusPengiriman`]="{ item }">
          <div class="caption">
            {{ lookupFExpedisi(item.fexpedisiBean).description }}
          </div>

          <div class="caption" >
            <v-chip x-small dark  :color="lookupEStatusPengirimanColor(item.statusPengiriman)">
              {{ lookupEStatusPengiriman(item.statusPengiriman).shortDesc }}
            </v-chip>
          </div>

        </template>
        <template v-slot:[`item.deliveryNumber`]="{ item }">
          <div class="fill-height">

            <div class="font-weight-regular">
                <span>
                  #{{ item.deliveryNumber }}
                </span>

            </div>
            <div class="font-weight-light caption" v-if="item.deliveryNumber">
              {{ dateFormattedDatefns(item.deliveryDate) }}
            </div>
            <div class="caption grey--text font-weight-light" v-if="item.remarkExt1">
              {{ item.remarkExt1 }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.fwarehouseBean`]="{ item }">
          <div class="fill-height ">
            <div class="font-weight-regular blue--text" >
              {{ lookupFWarehouseKode1(item.fwarehouseBean) }}
            </div>
            <div class="caption grey--text" >
              {{ lookupFDivision(item.fdivisionBean).description }}
            </div>

          </div>
        </template>

        <template v-slot:[`item.fcustomerBean`]="{ item }">
          <div class="fill-height">
            <div class="caption grey--text text--darken-1">
              <div>
                <span class="font-weight-light">{{ item.fcustomerBean.custno }}</span>
                <span class="font-italic caption ml-1"> {{ lookupFCustomerGroup(item.fcustomerBean.fcustomerGroupBean).kode1 }} </span>
                <span class="ml-1 font-weight-bold">
                  {{ item.fcustomerBean.custname }}
                </span>
              </div>
              <div>
                <span>{{ item.fcustomerBean.address1 }}</span>
                <span>{{ item.fcustomerBean.address2 }}</span>
              </div>
              <div>
                {{ item.fcustomerBean.city }}
                <v-icon x-small v-if="item.fcustomerBean.phone" class="mx-1">mdi-phone-classic</v-icon>{{ item.fcustomerBean.phone }}
              </div>
            </div>
          </div>
        </template>

        <template v-slot:[`item.amountAfterDiscPlusRpAfterPpn_FG`]="{ item }">
          <div class="fill-height">
            <div class="d-flex">
              <span class="">
                {{ formattedCurrencyValue('',((parseFloat(item.amountAfterDiscPlusRpAfterPpn_FG))).toFixed()) }}
              </span>

            </div>

          </div>
        </template>

        <template v-slot:[`item.statusActive`]="{ item }">
          <v-chip
              :color="getColorStatusActive(item.statusActive)"
              dark
              small
          >
            {{ item.statusActive==true?'Aktif': '-' }}
          </v-chip>
        </template>


        <template v-slot:[`item.modified`]="{ item }">
          <div  class="text-caption small font-weight-light grey--text">
            <div>{{ dateFormattedDatefnsWithTime(item.created) }}</div>
            <span>{{ dateFormattedDatefnsWithTimeShort(item.modified) }}</span>
            <span class="ml-1">{{ stringMaxLenght(item.modifiedBy) }} </span>
          </div>

          <div v-if="false">
            <v-btn
                @click="showDialogEdit(item)"
                icon
                :disabled="multiSelect"
            >
              <v-icon
                  small
                  color="warning"
              >
                mdi-pencil
              </v-icon>
            </v-btn>

<!--            <v-btn-->
<!--                @click="deleteDialogShow(item)"-->
<!--                icon-->
<!--                :disabled="multiSelect || item.deliveryNumber !='' "-->
<!--            >-->
<!--              <v-icon-->
<!--                  small-->
<!--                  color="red accent-4"-->
<!--              >-->
<!--                mdi-delete-->
<!--              </v-icon>-->
<!--            </v-btn>-->

          </div>

        </template>

      </v-data-table>
    </div>

    <v-container>
      <v-row justify="end" align="center">
        <v-col
            cols="6"
            md="3"
            sm="3"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>

        <v-col
            cols="10"
            md="9"
            sm="9"
            align="right"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <ConfirmDialogStatusOnPacking
        ref="refConfirmDialogStatusOnPacking"
        @eventFromConfirmDialog="statusPackingCofirmed"
    ></ConfirmDialogStatusOnPacking>
    <ConfirmDialogCreateAwb
        ref="refConfirmDialogCreateAwb"
        @eventFromConfirmDialog="createAwbCofirmed"
    ></ConfirmDialogCreateAwb>
    <ConfirmDialogPrintAwb
        ref="refConfirmDialogPrintAwb"
        @eventFromConfirmDialog="printAwbCofirmed"
    ></ConfirmDialogPrintAwb>

    <FtSaleshTimeLineDialog
        ref="refFtSaleshTimeLineDialog"
    ></FtSaleshTimeLineDialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-card>

</template>

<script>
import FtSaleshService from '@/services/apiservices/ft-salesh-service';
import FileService from "@/services/apiservices/file-service"

import ConfirmDialogStatusOnPacking from "@/components/utils/ConfirmDialog";
import ConfirmDialogCreateAwb from "@/components/utils/ConfirmDialog";
import ConfirmDialogPrintAwb from "@/components/utils/ConfirmDialog";
import FtSalesh from '@/models/ft-salesh'

import EStatusPengiriman, {EStatusPengirimans} from "@/models/e-status-pengiriman";
import FtSaleshTimeLineDialog from "@/components/sales/FtSaleshTimeLineDialog";

import writeXlsxFile from 'write-excel-file'
import FileSaver from 'file-saver'
import ESchemaSalesDetail from "@/models/e-schema-sales-detail";
import ESchemaSalesExt from "@/models/e-schema-sales-ext";
import ESchemaSales from "@/models/e-schema-sales";

import FtSaleshFilter from "@/models/payload/ft-salesh-filter";
import ERole from "@/models/e-role";

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import { dateFormattedDatefnsWithTime,  dateFormattedDatefns, dateFormattedDatefnsShort, dateFormattedDatefnsWithTimeShort} from "@/utils/date-utils"
import ESchemaSalesCs from "@/models/e-schema-sales-cs";
import FTaxService from "@/services/apiservices/f-tax-service";
import FtSaleshFilterExt from "@/components/sales/FtSaleshFilterExt";

export default {
  components: {
    FtSaleshFilterExt, DateRangePicker, FtSaleshTimeLineDialog,
    ConfirmDialogStatusOnPacking, ConfirmDialogCreateAwb, ConfirmDialogPrintAwb

  },
  data () {
    return {
      loading: false,
      title: 'SALES INVOICE',

      // date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,
      filterOrderDateFrom: '',
      menuDate2: false,
      filterOrderDateTo: '',

      datePickerRangeInv: {
        startDate: function(d){ d.setDate(d.getDate()-2); return d}(new Date),
        endDate: new Date(),
      },
      dprLocale: {
        direction: 'ltr', //direction of text
        format: 'dd/mm/yyyy',
        separator: ' - ', //separator between the two ranges
        applyLabel: 'Terapkan',
        cancelLabel: 'Batal',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        // daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        // monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 1, //ISO first day of week - see moment documenations for details
      },
      dprAutoApply:true,
      dprShowDropdowns:true,


      showFilter: false,

      filterFdivisions: [],
      filterFsalesmans: [],
      filterFstores: [],
      filterFexpedisies:[],
      filterFmaterials: [],
      filterEstatusPengiriman: [],
      filterFwarehouses: [],

      filterByGroupMaterialOrdered: [],
      itemsGroupMaterialOrdered:[],

      snackbar: false,
      snackBarMesage: '',

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 20, 40, 75, 150, 500, 1000, 2500, 5000],

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '7%',
          sortable: true
        },
        { text: 'No.INV', value: 'invoiceno', width:'10%' },
        // { text: 'Status', value: 'statusPengiriman', width: '10%' },
        // { text: 'AWB', value: 'deliveryNumber', width: '10%' },
        { text: 'Member/Customer', value: 'fcustomerBean', width: '25%' },
        { text: 'Warehouse', value: 'fwarehouseBean', width: '10%' },
        { text: 'Total+Ppn', value: 'amountAfterDiscPlusRpAfterPpn_FG', width: '15%'  },
        { text: 'Modified', value: 'modified', width: '10%'},
      ],

      // formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      ftSaleshs:[
        new FtSalesh(1, "")
      ],
      // itemsFDivision: [],
      itemsDestAddr:[],
      itemsFtSalesdItems:[],

      itemsEStatusPengiriman: EStatusPengirimans,

      boldClass: 'font-weight-bold',
      redClass: 'red--text'
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          if (this.showFilter){
            this.runExtendedFilter()
          }else {
            this.fetchFtSalesh()
          }
        }

      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage==1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)

          // this.fetchFtSalesh()
          if (this.showFilter){
            this.runExtendedFilter()
          }else {
            this.fetchFtSalesh()
          }

        }

        // console.log("page size = " + value + " >> " + this.totalPages)

      }
    },
    multiSelect:function (value){
      if (value==false){
        this.selectedItems = []
      }
    }

  },
  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },
    haveRoleAdminMutlak(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN")
        ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleAdmin(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN") ||
            this.currentUser.roles.includes("ROLE_ADMIN1") ||
            this.currentUser.roles.includes("ROLE_ADMIN2")
        ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleInventory(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_INVENTORY") ){
          isValid=true
        }
      }
      return isValid
    },
    haveRolePiutang(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_PIUTANG") ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleSalesOrCS(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_CS") || this.currentUser.roles.includes("ROLE_SALES") ||
            this.currentUser.roles.includes("ROLE_RSL_1")  || this.currentUser.roles.includes("ROLE_RSL_2") ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleCSOnly(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_CS") ){
          isValid=true
        }
      }
      return isValid
    },

    formMode: {
      get () {
        return this.$store.state.sales.formMode
      },
      set (value) {
        this.$store.dispatch('sales/updateFormMode', value)
      }
    },

    ftSaleshsFiltered(){
      return this.ftSaleshs
    },

    itemsFDivision(){
      return this.$store.state.data.listFDivision
    },
    itemsFMaterial(){
      return this.$store.state.data.listFMaterial
    },
    itemsFExpedisi(){
      return this.$store.state.data.listFExpedisi
    },
    itemsFWarehouse(){
      return this.$store.state.data.listFWarehouse
    },
    itemsFWarehouseActive(){
      return this.$store.state.data.listFWarehouse.filter(x => x.statusActive===true)
    },
    itemsFSalesman(){
      const listFSalesman = []

      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS].some(x =>this.currentUser.roles.includes(x)) ) {
        this.$store.state.data.listFSalesman.filter(x => x.id  === this.currentUser.salesmanOf).forEach( item =>{
          listFSalesman.push(item)
        })
      }else {
        this.$store.state.data.listFSalesman.forEach( item =>{
          listFSalesman.push(item)
        })
      }

      return listFSalesman
    },
    itemsFStore(){
      return this.$store.state.data.listFStore
    },

    itemsFCustomer(){
      return this.$store.state.data.listFCustomer
    },
    itemsFCustomerGroup(){
      return this.$store.state.data.listFCustomerGroup
    }


  },
  methods: {

    showFilterDialog(){
      this.showFilter = !this.showFilter

      const itemPayload = Object
      itemPayload.orderDateFrom =  this.datePickerRangeInv.startDate
      itemPayload.orderDateTo =  this.datePickerRangeInv.endDate
      itemPayload.invoiceDateFrom =  this.datePickerRangeInv.startDate
      itemPayload.invoiceDateTo =  this.datePickerRangeInv.endDate

      /**
       * TIDAK PERLU
       */
      // FtSaleshService.getAllFtSaleshContainingExt(itemPayload).then(
      //     response =>{
      //       console.log(response)
      //     },
      //     error => {
      //       console.log(error)
      //     }
      // )

    },

    showConfirmDialogPrintAwb(){
      this.$refs.refConfirmDialogPrintAwb.showDialog("Yakin akan Print AWB/Resi? (Tidak Boleh Mencampur Print Resi Lebih dari Satu Ekspedisi)")
    },
    printAwbCofirmed(value){
      if (value ==="OKE"){
        let itemsToPrint = []
        let expedisiName = ''

        this.selectedItems.forEach( item=>{
          if (item.statusPengiriman ==EStatusPengiriman.ON_PACKING  ){
          // if (item.statusPengiriman ==EStatusPengiriman.CONFIRMED || item.statusPengiriman ==EStatusPengiriman.ON_PACKING ){

            if (item.deliveryNumber !== ''){
              itemsToPrint.push(item.id)
              if (expedisiName === ''){
                expedisiName = this.lookupFExpedisi(item.fexpedisiBean).description
              }

            }
          }
          // console.log(JSON.stringify(itemsToPrint))
          this.$refs.refConfirmDialogPrintAwb.setDialogState(false)
        })

        // console.log(expedisiName)

        if (expedisiName.toUpperCase().includes("JNE")){
          FtSaleshService.postReportJasperResiJne(itemsToPrint).then(
              response=> {
                // console.log(response.data)

                let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file_resi_jne.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();

              },
              error =>{
                console.log(error)
              }
          )

        }else if (expedisiName.toUpperCase().includes("JNT") || expedisiName.toUpperCase().includes("J&T") ){
          FtSaleshService.postReportJasperResiJnt(itemsToPrint).then(
              response=> {
                // console.log(response.data)

                let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file_resi_jnt.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();

              },
              error =>{
                console.log(error)
              }
          )
        }else if (expedisiName.toUpperCase().includes("ID") && expedisiName.toUpperCase().includes("EXPRESS") ){
          FtSaleshService.postReportJasperResiIdExpress(itemsToPrint).then(
              response=> {
                // console.log(response.data)

                let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file_resi_id_express.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();

              },
              error =>{
                console.log(error)
              }
          )
        }else if (expedisiName.toUpperCase().includes("SI") && expedisiName.toUpperCase().includes("CEPAT") ){
          FtSaleshService.postReportJasperResiSiCepat(itemsToPrint).then(
              response=> {
                // console.log(response.data)

                let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file_resi_si_cepat.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();

              },
              error =>{
                console.log(error)
              }
          )
        }else {
          console.log('Tidak ada yang di print')
        }


      }

    },
    showConfirmDialogStatusOnPacking(){
      this.$refs.refConfirmDialogStatusOnPacking.showDialog("Yakin akan Mememberikan status On-Packing?")
    },
    statusPackingCofirmed(value){
      if (value ==="OKE"){
        /**
         * KENAPA UPDATE STATUS PACKING DUA KALI
         */
        this.$refs.refConfirmDialogStatusOnPacking.setDialogState(false)
        this.selectedItems.forEach( item => {
          if (item.statusPengiriman ==EStatusPengiriman.CONFIRMED){
            item.statusPengiriman  = EStatusPengiriman.ON_PACKING

            FtSaleshService.updateFtSalesh(item).then(
                response => {
                  const index = this.ftSaleshsFiltered.indexOf(item)

                  let newItem = Object.assign({}, response.data)
                  // newItem.statusPengiriman = EStatusPengiriman.ON_PACKING
                  this.ftSaleshsFiltered.splice(index, 1, newItem)

                },
                error => {
                  this.formDialogOptions.errorMessage = error.response.data.message
                }
            )
          }

        })
      }
    },


    showConfirmDialogCreateAwb(){
      // this.$refs.refConfirmDialogCreateAwb.showDialog("Yakin akan Mememberikan Menerbitkan Air Way Bill (Nomor Resi)?")
    },
    createAwbCofirmed(value){
      if (value ==="OKE") {
        this.$refs.refConfirmDialogCreateAwb.setDialogState(false)
        this.selectedItems.forEach(item => {
          if (item.statusPengiriman == 0) {
            const itemModified = Object.assign({}, item)
            this.$refs.refFormDialog.saveProses(itemModified, false)
          }
        })
      }

    },

    changeDatePickerRange(){
      console.log(`${this.datePickerRangeInv.startDate} >> ${this.datePickerRangeInv.endDate}`)
    },

    runExtendedFilterFromChild(value){
      this.runExtendedFilter(value)
    },
    runExtendedFilter(value) {
      // console.log(JSON.stringify(value))
      // console.log(value.filterByGroupMaterialOrdered)

      let ftSaleshFilter = new FtSaleshFilter()

      if (value instanceof FtSaleshFilter ) {
        ftSaleshFilter = value
      } else {

        ftSaleshFilter.fdivisionIds =  this.filterFdivisions //dilakukan pada back-end untuk security

        if (this.filterFsalesmans.length >0){
          ftSaleshFilter.fsalesmanIds = this.filterFsalesmans
        }else {
          // console.log(`>> ${JSON.stringify(this.itemsFSalesman)}`)
          ftSaleshFilter.fsalesmanIds = this.itemsFSalesman.map( item => item.id)
        }

        if (this.filterFstores.length >0){
          ftSaleshFilter.fstoreIds = this.filterFstores
        }else {
          ftSaleshFilter.fstoreIds = this.itemsFStore.map( item => item.id)
        }
        if (this.filterFexpedisies.length >0){
          ftSaleshFilter.fexpedisiIds =  this.filterFexpedisies
        }else {
          ftSaleshFilter.fexpedisiIds =  this.itemsFExpedisi.map( item => item.id)
        }
        if (this.filterEstatusPengiriman.length >0){
          ftSaleshFilter.statusPengirimanIds =  this.filterEstatusPengiriman
        }else {
          ftSaleshFilter.statusPengirimanIds = this.itemsEStatusPengiriman.map( item => item.id)
        }
        if (this.filterFmaterials.length >0){
          ftSaleshFilter.fmaterialIds =  this.filterFmaterials
        }else {
          ftSaleshFilter.fmaterialIds = this.itemsFMaterial.map( item => item.id)
        }
        if (this.filterFwarehouses.length >0){
          ftSaleshFilter.fwarehouseIds =  this.filterFwarehouses
        }else {
          ftSaleshFilter.fwarehouseIds = this.itemsFWarehouse.map( item => item.id)
        }

        // console.log(`${this.datePickerRangeInv.startDate} >> ${this.datePickerRangeInv.endDate}`)
        ftSaleshFilter.orderDateFrom =  this.datePickerRangeInv.startDate
        ftSaleshFilter.orderDateTo =  this.datePickerRangeInv.endDate
        ftSaleshFilter.invoiceDateFrom =  this.datePickerRangeInv.startDate
        ftSaleshFilter.invoiceDateTo =  this.datePickerRangeInv.endDate

        ftSaleshFilter.listRemark = this.filterByGroupMaterialOrdered
      }

      ftSaleshFilter.pageNo = this.currentPage
      ftSaleshFilter.pageSize = this.pageSize
      ftSaleshFilter.sortBy = "id"
      ftSaleshFilter.order = "DESC"
      ftSaleshFilter.search = this.search

      // ftSaleshFilter.listRemark = this.filterByGroupMaterialOrdered

      // console.log(JSON.stringify(ftSaleshFilter))
      // console.log(JSON.stringify(ftSaleshFilter.listRemark))

      if (ftSaleshFilter.listRemark.length >0){
        FtSaleshService.getAllFtSaleshContainingAndRemarkInExt(ftSaleshFilter).then(
            response =>{
              const { items, totalPages} = response.data
              this.ftSaleshs = items
              this.totalPaginationPages = totalPages

            },
            error =>{
              console.log(error)
            }
        )
      }else {
        FtSaleshService.getAllFtSaleshContainingExtLight(ftSaleshFilter).then(
            response =>{
              const { items, totalPages} = response.data
              this.ftSaleshs = items
              this.totalPaginationPages = totalPages

              // console.log(JSON.stringify(items))

              this.itemsGroupMaterialOrdered = []
              this.ftSaleshs.forEach(item => {
                if (item.remark.trim() != ''){
                  this.itemsGroupMaterialOrdered.push(item.remark)
                }
              })

            },
            error =>{
              console.log(error)
            }
        )
      }


    },

    searchOnEnter(event){
      if (this.search !== event.target.value) {
        this.currentPage = 1
        this.search = event.target.value

        if (this.showFilter){
          this.runExtendedFilter()
        }else {
          this.fetchFtSalesh()
        }


      }
    },

    fetchParent(){
      this.$store.dispatch('data/loadFDivisionByOrgLevel')

      let fsalesmanBean = 0
      if (this.currentUser.roles != undefined){
        if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2].some(x =>this.currentUser.roles.includes(x)) ) {
          fsalesmanBean = this.currentUser.salesmanOf
        }
      }

      /**
       * Pada suatu saat akan tidak mungkin untuk load semua Fcustomer
       */
      const payload = {'fsalesmanBean': fsalesmanBean}
      payload.toString()

      this.$store.dispatch('data/loadFWarehouseExpedisiAvailable')

      this.$store.dispatch('data/loadFMaterial')
      this.$store.dispatch('data/loadFExpedisi')
      this.$store.dispatch('data/loadFWarehouse')

      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2].some(x =>this.currentUser.roles.includes(x)) ) {
        this.$store.dispatch('data/loadFSalesmanChildrenAndMe')
      }else {
        this.$store.dispatch('data/loadFSalesman')
      }
      // this.$store.dispatch('data/loadFSalesman')

      this.$store.dispatch('data/loadFPayMethod')
      this.$store.dispatch('data/loadFStore')

      this.$store.dispatch('data/loadFCustomerGroup')

      FTaxService.getAllFTax().then(
          response =>{
            this.$store.dispatch('sales/reloadItemsFTax', response.data)
          }
      )


    },

    fetchFtSalesh() {

      this.loading =true
      FtSaleshService.getAllFtSaleshContaining(this.currentPage, this.pageSize, "created", "DESC", this.search).then(
          response => {

            // console.log(JSON.stringify(response.data.items))

            this.loading =false
            const { items, totalPages} = response.data
            this.ftSaleshs = items

            this.totalPaginationPages = totalPages


            this.loading = false

          },
          error => {
            this.loading = false
            console.log(error.response)
          }
      )

    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    eventEditFormDialog1() {
    },
    eventCreateFormDialog1() {
    },

    eventCloseFormDialog1(){
      // this.fetchFtSalesh(true)
      if (this.showFilter){
        this.runExtendedFilter()
      }else {
        this.fetchFtSalesh(true)
      }
    },

    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, '')
        this.itemSelectedIndex = -1
      })

    },

    importExcelCompleted(value){
      if (value ==='OKE'){
        this.fetchFtSalesh()
      }
    },

    exportSalesDetailAccounting(){

    },

    exportSalesDetailBukaPaket(){

      let ftSalesIds = this.ftSaleshsFiltered.map( item =>
          item.id
      )

      FtSaleshService.getAllFtSalesDetailByIds(ftSalesIds).then(
          response => {

            let schema = ESchemaSalesDetail
            let fileBits = 'file_rekap_sales_bukapaket.xlsx'

            let ftSaleshSorted = response.data
            try {
              ftSaleshSorted = response.data.slice().sort(function(a, b){
                return (a.orderDate > b.orderDate) ? 1 : -1;
              });
            }catch (e){e.toString()}

            let ftSaleshReports = []
            ftSaleshSorted.forEach( item => {
              let ftSalesDetail = item
              ftSalesDetail.fdivision = this.lookupFDivision(ftSalesDetail.fdivisionBean)
              ftSalesDetail.fsalesman = this.lookupFSalesman(ftSalesDetail.fsalesmanBean)
              ftSalesDetail.fstore = this.lookupFStore(ftSalesDetail.fstoreBean)
              ftSalesDetail.fcustomer = this.lookupFCustomer(ftSalesDetail.fcustomerBean)
              ftSalesDetail.fcustomer = this.lookupFCustomer(ftSalesDetail.fcustomerBean)
              ftSalesDetail.fwarehouse = this.lookupFWarehouse(ftSalesDetail.fwarehouseBean)
              ftSalesDetail.fexpedisi = this.lookupFExpedisi(ftSalesDetail.fexpedisiBean)
              ftSalesDetail.statusKirim = this.lookupEStatusPengiriman(ftSalesDetail.statusPengiriman).shortDesc

              ftSalesDetail.pcode = this.lookupFMaterial(ftSalesDetail.fmaterialBean).pcode
              ftSalesDetail.pname = this.lookupFMaterial(ftSalesDetail.fmaterialBean).pname

              if (ftSalesDetail.fmaterialBaseSprice==='NaN') ftSalesDetail.fmaterialBaseSprice = 0
              if (ftSalesDetail.discPersen==='NaN' || ftSalesDetail.discPersen === 'Infinity' ) ftSalesDetail.discPersen = 0
              if (ftSalesDetail.amountAfterDiscPlusRpAfterPpn_FG==='NaN') ftSalesDetail.amountAfterDiscPlusRpAfterPpn_FG = 0
              /**
               * Jangan Buka Dulu
               */
              if (this.haveRoleAdminMutlak || this.haveRolePiutang) {
                ftSalesDetail.sprice2ToDist1 = this.lookupFMaterial(ftSalesDetail.fmaterialBean).sprice2ToDist1
                ftSalesDetail.sprice2ToDist2 = this.lookupFMaterial(ftSalesDetail.fmaterialBean).sprice2ToDist2
                ftSalesDetail.sprice2ToDist3 = this.lookupFMaterial(ftSalesDetail.fmaterialBean).sprice2ToDist3
              }

              ftSaleshReports.push(ftSalesDetail)

            })

            writeXlsxFile(ftSaleshReports, {
              schema,
              fileName: fileBits
            })

            let file = new File(fileBits, "file_rekap_sales.txt", {type: "xlsx/plain;charset=utf-8"});
            FileSaver.saveAs(file);

          },
          error => {
            this.loading = false
            console.log(error.response)
          }
      )

    },

    exportSales(){
      if (this.showFilter ==false){
        FtSaleshService.getAllFtSaleshContaining(this.currentPage, this.pageSize, "created", "DESC", this.search).then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.exportSalesToFile(response.data.items)
            },
            error => {
              this.loading = false
              console.log(error.response)
            }
        )
      }else {
        this.exportSalesToFile(this.ftSaleshsFiltered)
      }

    },

    exportSalesToFile(listFtSalesh){
      // console.log(JSON.stringify(response.data))

      let schema = ESchemaSales
      if (this.haveRoleAdminMutlak || this.haveRolePiutang){
        schema = ESchemaSalesExt
      }

      let fileBits = 'file_rekap_sales.xlsx'

      let ftSaleshSorted = listFtSalesh.slice().sort(function(a, b){
        return (a.orderDate > b.orderDate) ? 1 : -1;
      });

      let ftSaleshReports = []
      ftSaleshSorted.forEach( item => {
        let ftSalesh = item
        ftSalesh.fdivision = this.lookupFDivision(ftSalesh.fdivisionBean)
        ftSalesh.fsalesman = this.lookupFSalesman(ftSalesh.fsalesmanBean)
        ftSalesh.fstore = this.lookupFStore(ftSalesh.fstoreBean)
        ftSalesh.fcustomer = this.lookupFCustomer(ftSalesh.fcustomerBean)
        ftSalesh.fcustomer = this.lookupFCustomer(ftSalesh.fcustomerBean)
        ftSalesh.fwarehouse = this.lookupFWarehouse(ftSalesh.fwarehouseBean)
        ftSalesh.fexpedisi = this.lookupFExpedisi(ftSalesh.fexpedisiBean)
        ftSalesh.statusKirim = this.lookupEStatusPengiriman(ftSalesh.statusPengiriman).shortDesc

        ftSalesh.items = ftSalesh.remark

        if (ftSalesh.codRp >0){

          ftSalesh.totalBayar = ftSalesh.codRp
          ftSalesh.byPenanganan = parseFloat(item.codRp) - (parseFloat(item.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(item.ongkirRp))
        }else {
          ftSalesh.totalBayar = ftSalesh.amountAfterDiscPlusRpAfterPpn_FG + ftSalesh.ongkirRp
          ftSalesh.byPenanganan = 0
        }

        if (this.haveRoleAdminMutlak || this.haveRolePiutang) {
          ftSalesh.amountSalesToDist = this.lookupFtSalesdItemsSalesToDist(ftSalesh)
          ftSalesh.amountSalesToAgen = this.lookupFtSalesdItemsSalesToAgen(ftSalesh)
          ftSalesh.amountSalesToReseller = this.lookupFtSalesdItemsSalesToReseller(ftSalesh)
        }

        ftSaleshReports.push(ftSalesh)

      })

      writeXlsxFile(ftSaleshReports, {
        schema,
        fileName: fileBits
      })

      let file = new File(fileBits, "file_rekap_sales.txt", {type: "xlsx/plain;charset=utf-8"});
      FileSaver.saveAs(file);

    },


    exportSalesCS(){

      let schema = ESchemaSalesCs

      let fileBits = 'file_rekap_sales_cs.xlsx'

      let ftSaleshSorted = this.ftSaleshsFiltered.slice().sort(function(a, b){
        return (a.orderDate > b.orderDate) ? 1 : -1;
      });

      let ftSaleshReports = []
      ftSaleshSorted.forEach( item => {
        let ftSalesh = item
        ftSalesh.fdivision = this.lookupFDivision(ftSalesh.fdivisionBean)
        ftSalesh.fsalesman = this.lookupFSalesman(ftSalesh.fsalesmanBean)
        ftSalesh.fstore = this.lookupFStore(ftSalesh.fstoreBean)
        ftSalesh.fcustomer = this.lookupFCustomer(ftSalesh.fcustomerBean)
        ftSalesh.fcustomer = this.lookupFCustomer(ftSalesh.fcustomerBean)
        ftSalesh.fwarehouse = this.lookupFWarehouse(ftSalesh.fwarehouseBean)
        ftSalesh.fexpedisi = this.lookupFExpedisi(ftSalesh.fexpedisiBean)
        ftSalesh.statusKirim = this.lookupEStatusPengiriman(ftSalesh.statusPengiriman).shortDesc

        // ftSalesh.items = this.lookupFtSalesdItems(ftSalesh)
        ftSalesh.items = ftSalesh.remark

        if (ftSalesh.codRp >0){

          ftSalesh.totalBayar = ftSalesh.codRp
          ftSalesh.byPenanganan = parseFloat(item.codRp) - (parseFloat(item.amountAfterDiscPlusRpAfterPpn_FG) + parseFloat(item.ongkirRp))
        }else {
          ftSalesh.totalBayar = ftSalesh.amountAfterDiscPlusRpAfterPpn_FG + ftSalesh.ongkirRp
          ftSalesh.byPenanganan = 0
        }

        ftSaleshReports.push(ftSalesh)

      })

      writeXlsxFile(ftSaleshReports, {
        schema,
        fileName: fileBits
      })

      let file = new File(fileBits, "file_rekap_sales.txt", {type: "xlsx/plain;charset=utf-8"});
      FileSaver.saveAs(file);

    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },
    removeAutoCompleteFExpedisi (item) {
      const index = this.filterFexpedisies.indexOf(item.id)
      if (index >= 0) this.filterFexpedisies.splice(index, 1)
    },
    removeAutoCompleteFWarehouse (item) {
      const index = this.filterFwarehouses.indexOf(item.id)
      if (index >= 0) this.filterFwarehouses.splice(index, 1)
    },

    removeAutoCompleteFMaterial (item) {
      const index = this.filterFmaterials.indexOf(item.id)
      if (index >= 0) this.filterFmaterials.splice(index, 1)
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFSalesman (fsalesmanBean) {
      const str = this.itemsFSalesman.filter(x => x.id===fsalesmanBean)

      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFCustomer (fcustomerBean) {
      const str = this.itemsFCustomer.filter(x => x.id===fcustomerBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFCustomerGroup (fcustomerGroupBean) {
      const str = this.itemsFCustomerGroup.filter(x => x.id===fcustomerGroupBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFStore (fstoreBean) {
      const str = this.itemsFStore.filter(x => x.id===fstoreBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFExpedisi (fexpedisiBean) {
      const str = this.itemsFExpedisi.filter(x => x.id===fexpedisiBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFWarehouseKode1 (fwarehouseBean) {
      const str = this.itemsFWarehouse.filter(x => x.id===fwarehouseBean)
      if (str.length>0){
        return str[0].kode1
      }else {
        return '-'
      }
    },

    lookupFWarehouse (fwarehouseBean) {
      const str = this.itemsFWarehouse.filter(x => x.id===fwarehouseBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFtSalesdItemsSalesToDist (ftSaleshBean) {
      const ftSalesdItemsFiltered = ftSaleshBean.listFtSalesdItems
      if (ftSalesdItemsFiltered.length>0){
        let nilai = 0
        ftSalesdItemsFiltered.forEach(item => {
          const fmaterial = this.lookupFMaterial(item.fmaterialBean)
          try {
            nilai += (item.qty* fmaterial.sprice2ToDist1)
          }catch (e) {
            console.log(e)
          }
        })

        return nilai
      }else {
        return 0
      }
    },
    lookupFtSalesdItemsSalesToAgen (ftSaleshBean) {
      const ftSalesdItemsFiltered = ftSaleshBean.listFtSalesdItems
      if (ftSalesdItemsFiltered.length>0){
        let nilai = 0
        ftSalesdItemsFiltered.forEach(item => {
          const fmaterial = this.lookupFMaterial(item.fmaterialBean)
          try {
            nilai += (item.qty* fmaterial.sprice2ToDist2)
          }catch (e) {
            console.log(e)
          }
        })

        return nilai
      }else {
        return 0
      }
    },
    lookupFtSalesdItemsSalesToReseller (ftSaleshBean) {
      const ftSalesdItemsFiltered = ftSaleshBean.listFtSalesdItems
      if (ftSalesdItemsFiltered.length>0){
        let nilai = 0
        ftSalesdItemsFiltered.forEach(item => {
          const fmaterial = this.lookupFMaterial(item.fmaterialBean)
          try {
            nilai += (item.qty* fmaterial.sprice2ToDist3)
          }catch (e) {
            console.log(e)
          }
        })

        return nilai
      }else {
        return 0
      }
    },

    lookupFMaterialPcode (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return `${str[0].pcode}`
      }else {
        return '-'
      }
    },
    lookupFMaterial (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupEStatusPengiriman (statusPengiriman) {
      const str = this.itemsEStatusPengiriman.filter(x => x.id===statusPengiriman)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupEStatusPengirimanColor (statusPengiriman) {
      const str = this.itemsEStatusPengiriman.filter(x => x.id===statusPengiriman)
      if (str.length>0){
        return `${str[0].color}`
      }else {
        return '-'
      }
    },
    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/no_image_available.jpeg'
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    dateFormattedDatefns(value){
      return dateFormattedDatefns(value)
    },
    dateFormattedDatefnsShort(value){
      return dateFormattedDatefnsShort(value)
    },
    dateFormattedDatefnsWithTime(value){
      return dateFormattedDatefnsWithTime(value)
    },
    dateFormattedDatefnsWithTimeShort(value){
      return dateFormattedDatefnsWithTimeShort(value)
    },

    stringMaxLenght(value){
      if (value ==undefined) value = ''
      let str = value
      if (value.length>6) str = value.substr(0, 5)
      return str
    }

  },

  mounted() {

    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchParent()
      this.fetchFtSalesh()
    }

    if (this.currentUser.roles != undefined){
      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2].some(x =>this.currentUser.roles.includes(x)) ) {
        this.pageSizes= [10, 20, 40, 75, 150]
      }
    }


  }

}
</script>

<style scoped>
.single-line-height{
  line-height: 0.9em;
}

</style>
